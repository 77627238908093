<template>
  <card-form>

    <div class="row mt-4">
      <f-input 
        name="nome" 
        :disabled="disabled"
        v-model="model.nome" 
        v-validate="{required: true}" 
        data-vv-as="Nome"
        label="Nome"
        :modelErrors="errors"
        :cols="6"
      />
      <f-input 
        name="ans" 
        :disabled="disabled"
        v-model="model.ans" 
        v-validate="{required: true}" 
        data-vv-as="Código ANS"
        label="Código ANS"
        :modelErrors="errors"
        :cols="3"
      />
      <f-input 
        name="cnpj" 
        :disabled="disabled"
        v-model="model.cnpj" 
        v-mask="'##.###.###/####-##'"
        v-validate="{required: false, cnpj: true}" 
        data-vv-as="CNPJ"
        label="CNPJ"
        :modelErrors="errors"
        :cols="3"
      />
      <f-switch
        name="ativo"
        :disabled="disabled"
        v-model="model.ativo"
        :cols="12"
        :label="model.ativo ? 'Ativo' : 'Inativo'"
      />

      <div>
        <div class="separador my-4"></div>
        <div class="row">
          <label>Logotipo</label>
          <div>
            <img v-if="logotipo != null" 
              :src="logotipo" 
              style="max-width: 240px; max-height: 120px; width: auto;" 
              class="mt-2 mb-4 mr-3 me-4" />

            <span v-if="hideUpload" @click="()=> { hideUpload = false; logotipo = null; }"><i class="fas fa-sync-alt fa-3x c-pointer" title="Alterar logotipo"></i></span> 
          </div>
          
          <input v-if="!disabled && !hideUpload" 
            accept="image/*" 
            type="file" 
            @change="uploadFile" 
            ref="file" />
          
        </div>
      </div>
      
    </div>

    <template #footer>
      <button v-if="!disabled" class="btn btn-success px-5" @click="submeter" type="button">{{ model.id == 0 ? 'Criar' : 'Alterar' }}</button>
      <button class="btn btn-info px-5" @click="() => {$router.push({name: 'sistema.convenio.lista'})}" type="button">Voltar</button>
      <button v-if="model.id > 0" class="btn btn-outline-info px-4" @click="() => {$router.push({name: 'sistema.convenio.usuarios.lista', params: { id: model.id, backTo: $router.currentRoute.path } })}" type="button" title="Usuários">
        <i class="uil uil-users-alt"></i>
      </button>
    </template>

  </card-form>

</template>

<script>
import CardForm from "@/components/Ui/CardForm";
import { Validator } from 'vee-validate';
import { getConvenio, criarConvenio, atualizarConvenio, getConvenioLogo } from "@/services/convenio.service";
import { trataErro, trataInsertSucesso, trataUpdateSucesso } from "../../../../utils/tratar.retornos";
import { messageError } from '../../../../utils/messages'
import validateCnpj from '../../../../utils/validator.cnpj'

export default {
  components: {
    CardForm,
  },
  data() {
    return {
      disabled: true,
      logotipo: null,
      hideUpload: false,
      logoFile: null,

      model: {
        id: 0,
        nome: '',
        ativo: true,
        cnpj: '',
        ans: '',
        logotipoId: null,
      },
    };
  },
  mounted() {
    this.disabled = !this.$route.meta.editavel;
    if (this.$route.params.id && this.$route.params.id > 0) {
      getConvenio(this.$route.params.id).then(({ data }) => {
        this.model = data.resultado;
        if (this.model.logotipoId) {
          getConvenioLogo(this.model.logotipoId).then(({data}) => { 
            this.hideUpload = true;
            this.logotipo = data
          })
        }
      })
      .catch((e) => 
        trataErro(e, this)
      );
    }
    
    Validator.extend('cnpj', validateCnpj);
  },
  methods: {
    async submeter(event) {
      event.preventDefault()
      var isValid = await this.$validator.validateAll();
      if (isValid) {

        const formData = new FormData();
        Object.keys(this.model).forEach(key => { 
          formData.append(key, this.model[key])
        });
        formData.append('logotipo', this.logoFile);

        if (this.model.id == 0) {
          criarConvenio(formData).then(() => {
            trataInsertSucesso(this, 'sistema.convenio.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        } else {
          atualizarConvenio(formData).then(() => {
            trataUpdateSucesso(this, 'sistema.convenio.lista')
          })
          .catch((e) => 
            trataErro(e, this)
          )
        }
      }
    },

    uploadFile() {
      if (!this.$refs.file.files[0].type.startsWith("image/")) {
        this.$refs.file.value = null
        messageError(this, 'Tipo não suportado.')
        return;
      }
      this.logoFile = this.$refs.file.files[0];
    },
  },
};
</script>