var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-form",
    {
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              !_vm.disabled
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success px-5",
                      attrs: { type: "button" },
                      on: { click: _vm.submeter }
                    },
                    [_vm._v(_vm._s(_vm.model.id == 0 ? "Criar" : "Alterar"))]
                  )
                : _vm._e(),
              _c(
                "button",
                {
                  staticClass: "btn btn-info px-5",
                  attrs: { type: "button" },
                  on: {
                    click: function() {
                      _vm.$router.push({ name: "sistema.convenio.lista" })
                    }
                  }
                },
                [_vm._v("Voltar")]
              ),
              _vm.model.id > 0
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-info px-4",
                      attrs: { type: "button", title: "Usuários" },
                      on: {
                        click: function() {
                          _vm.$router.push({
                            name: "sistema.convenio.usuarios.lista",
                            params: {
                              id: _vm.model.id,
                              backTo: _vm.$router.currentRoute.path
                            }
                          })
                        }
                      }
                    },
                    [_c("i", { staticClass: "uil uil-users-alt" })]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "row mt-4" },
        [
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "nome",
              disabled: _vm.disabled,
              "data-vv-as": "Nome",
              label: "Nome",
              modelErrors: _vm.errors,
              cols: 6
            },
            model: {
              value: _vm.model.nome,
              callback: function($$v) {
                _vm.$set(_vm.model, "nome", $$v)
              },
              expression: "model.nome"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{required: true}"
              }
            ],
            attrs: {
              name: "ans",
              disabled: _vm.disabled,
              "data-vv-as": "Código ANS",
              label: "Código ANS",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.ans,
              callback: function($$v) {
                _vm.$set(_vm.model, "ans", $$v)
              },
              expression: "model.ans"
            }
          }),
          _c("f-input", {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##.###.###/####-##",
                expression: "'##.###.###/####-##'"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: false, cnpj: true },
                expression: "{required: false, cnpj: true}"
              }
            ],
            attrs: {
              name: "cnpj",
              disabled: _vm.disabled,
              "data-vv-as": "CNPJ",
              label: "CNPJ",
              modelErrors: _vm.errors,
              cols: 3
            },
            model: {
              value: _vm.model.cnpj,
              callback: function($$v) {
                _vm.$set(_vm.model, "cnpj", $$v)
              },
              expression: "model.cnpj"
            }
          }),
          _c("f-switch", {
            attrs: {
              name: "ativo",
              disabled: _vm.disabled,
              cols: 12,
              label: _vm.model.ativo ? "Ativo" : "Inativo"
            },
            model: {
              value: _vm.model.ativo,
              callback: function($$v) {
                _vm.$set(_vm.model, "ativo", $$v)
              },
              expression: "model.ativo"
            }
          }),
          _c("div", [
            _c("div", { staticClass: "separador my-4" }),
            _c("div", { staticClass: "row" }, [
              _c("label", [_vm._v("Logotipo")]),
              _c("div", [
                _vm.logotipo != null
                  ? _c("img", {
                      staticClass: "mt-2 mb-4 mr-3 me-4",
                      staticStyle: {
                        "max-width": "240px",
                        "max-height": "120px",
                        width: "auto"
                      },
                      attrs: { src: _vm.logotipo }
                    })
                  : _vm._e(),
                _vm.hideUpload
                  ? _c(
                      "span",
                      {
                        on: {
                          click: function() {
                            _vm.hideUpload = false
                            _vm.logotipo = null
                          }
                        }
                      },
                      [
                        _c("i", {
                          staticClass: "fas fa-sync-alt fa-3x c-pointer",
                          attrs: { title: "Alterar logotipo" }
                        })
                      ]
                    )
                  : _vm._e()
              ]),
              !_vm.disabled && !_vm.hideUpload
                ? _c("input", {
                    ref: "file",
                    attrs: { accept: "image/*", type: "file" },
                    on: { change: _vm.uploadFile }
                  })
                : _vm._e()
            ])
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }